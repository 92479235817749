import React from "react"
import { graphql } from "gatsby"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { v4 as uuidv4 } from "uuid"
import SectionHeading from "../UI/SectionHeading"
import Link from "../UI/Link"
import {
  bgClass,
  containerClass,
  linkText,
  line,
  smallImages
} from "./LeftTextWithRightBox.module.scss"

export const fragment = graphql`
  fragment leftTextWithRightBoxFragment on WpPage_Flexlayouts_FlexibleLayouts_LeftTextWithRightBox {
    backgroundImage {
      ...FullWidthImageQuery
    }
    headerSection
    headingType
    paragraph
    links {
      linkTo {
        title
        url
      }
    }
    boxHeader
    boxLink {
      title
      url
    }
    imgAndText {
      linkTo {
        title
        url
      }
      image {
        ...GatsbyImageQuery
      }
    }
  }
`
const LeftTextWithRightBox = ({
  headerSection,
  paragraph,
  links,
  boxHeader,
  boxLink,
  imgAndText,
  backgroundImage,
  headingType
}) => {
  const image = getImage(backgroundImage?.localFile)

  return (
    <div className={`${bgClass} pb-6 bg-pale-grey`}>

      <Container className={containerClass}>
        <Row className={`flex-column flex-md-row pt-xl-3`}>
          <Col md={6} className="px-md-0">
            <div className={`h-100`}>              
              <SectionHeading className="pt-5" text={headerSection} type={headingType} />              
              <p
                className={`text-start quicksand-500 py-4 pe-xl-7`}
                dangerouslySetInnerHTML={{ __html: paragraph }}
              />
              {links.map(item  => {
                return (
                  <>
                    <Link
                      key={uuidv4()}
                      classes={`${linkText} linkClass`}
                      link={item.linkTo.url}
                      title={item.linkTo.title}
                    />
                    <br />
                  </>
                )
              })}
            </div>
          </Col>
          <Col md={6}>
            <div
              className="px-4 py-3 mt-5 bg-white"
              style={{ borderRadius: "20px" }}>
              <div className="d-flex flex-column flex-lg-row pt-xl-3">
                <h3 className="pe-lg-7">{boxHeader}</h3>
                <Link
                  classes={`${linkText} linkClass`}
                  link={boxLink.url}
                  title={boxLink.title}
                />
              </div>
              <hr className={line} />
              {imgAndText.map((item, i) => {
                const smallImg = getImage(item.image?.localFile)
                return (
                  <Row className="my-4" key={uuidv4()}>
                    <Col xs={6} xl={5}>
                      <GatsbyImage
                        image={smallImg}
                        alt={smallImg?.altText}
                        className={smallImages}
                      />
                    </Col>
                    <Col xs={6} xl={7}>
                      <Link
                        classes="linkClass"
                        link={item.linkTo.url}
                        title={item.linkTo.title}
                      />
                    </Col>
                  </Row>
                )
              })}
            </div>
          </Col>
        </Row>
      </Container>
      {/* </div> */}
    </div>
  )
}

export default LeftTextWithRightBox